/* ---------------------------------------------------
NEW NAVIGATION BAR 
---------------------------------------------------*/
.navigation {
  display: flex;
  align-items: center;
  }
  .nav-btn {
    letter-spacing: 3px;
    padding: 0 1em;
    font-size: 12px;
    /* padding: 15px; */
    /* color: #ffffffb3; */
    font-family: "Roboto Condensed", sans-serif;
    text-decoration: none !important;
  }
  .nav-btn:hover {
    letter-spacing: 3.5px;
    transition: 0.3s;
  }
  .active, .nav-btn:hover {
    /* background-color: rgba(0, 102, 102, 0.563); */
    text-decoration: underline !important;
    /* border-radius: 10px; */
  }
    /* BOROWWED FORM DEV.TO */
    @import url('https://fonts.googleapis.com/css2?family=Roboto:it al,wght@0,500;1,400&display=swap');
    
    @media only screen and (max-width: 768px) {
      .containerr {
        width: 100vw;
        background-color: #3a3a3a !important;
        height: 2.9em;

      }
      .containerr .logo{
        width: 20% !important;
        padding: 4px;
      }
      .containerr .logo .main-logo{
        padding: 0;
        /* width: 100%; */
        Object-fit: contain;
      }
      .navigation {
        position: fixed;
        /* right: -100%; */
        top: -100%;
        flex-direction: column;
        background-color: #fff;
        width: 100vw;
        /* border-radius: 10px; */
        text-align: center;
        transition: 0.3s;
        box-shadow:0 10px 27px rgba(0, 0, 0, 0.05);
      }
      .barr {
        z-index: 1;
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-color: #636363;
      }
  
      .navigation.active {
          position: fixed;
          width: 100vw;
          top: 2.9em;
          background-color: #3a3a3abf !important;
      }
  
      .nav-btn {
          padding: 1.5em 5px;
      }
  
      .hamburger {
        display: block;
        cursor: pointer;
        z-index: 1;
        padding: 0.25em 1em 0.25em 0.25em;
        background-color: transparent;
      }
  
      .hamburger.active .barr:nth-child(2) {
        opacity: 0;
    }
  
    .hamburger.active .barr:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }
  
    .hamburger.active .barr:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
  }
    /* END BOROWWED FORM DEV.TO */

    /* @media screen and (max-width: 580px) {
      .containerr {
        padding: 20px 10px !important;
      }
      .containerr a {
        float: none;
        display: block;
        text-align: left;
      }
      .navigation {
        float: none;
      }
    } */

  .main-logo{
    padding: 8px;
    Width: 60%;
    /* height: 100%; */
    Object-fit: contain;
    /* background: linear-gradient(90deg, rgba(97, 98, 97, 0.8) 0%, rgba(216, 224, 223, 0.8) 100%); */
  }
/* ---------------------------------------------------
END OF NEW NAVIGATION BAR 
---------------------------------------------------*/


/* ------------------------------------------------
 START OF NEW TESTIMONIAL CAROUSELS 
 ------------------------------------------------*/

  @media only screen and (max-width: 768px) { 
    .carousel{
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 912px) { 
    .carousel{
      width: 100% !important;
    }
  }

  .contents{
    text-decoration: none !important;
  }

/* ------------------------------------------------
 END OF NEW TESTIMONIAL CAROUSELS 
 ------------------------------------------------*/

.container-fluid {
  background-color: #c4232a;
}
.ceoBg {
  background-image: linear-gradient(#dce0f5d4, #f9f9f9bc, #8392a4dc), url('../public/img/CEOBg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.technicianBg {
  background-image: linear-gradient(#dce0f5d4, #f9f9f9bc, #8392a4dc), url('../public/img/TECHNICIANBg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutUs-card {
  background-color: #dcdcdc	;
}

.computer-icon {
  Width: 15%;
  Aspect-ratio: 3/2;
  Object-fit: contain;
/* Mix-blend-mode: color-burn; */
}

.containers .cards
{
  /* max-width: 60em; */
  height: 6em;
  margin-top: 15px;
  padding: 1vw 1vw;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  transition: 0.3s ease-in-out;
  background: linear-gradient(0deg, rgba(119,119,118,0.8), rgba(119,119,118,0.6));
}

.containers .cards:hover
{
  height: 25vh;
  overflow: scroll;
}

.containers .cards .imgContainer
{
  position: relative;
  width: fit-content;
  height: fit-content;
  z-index: 1;
}

.containers .cards .imgContainer img
{
  max-width: 100%;
  border-radius: 4px;
}

.containers .cards .content
{
  position: relative;
  margin-top: 40px;
  padding: 1vh 2vw;
  /* padding: 10px 15px; */
  text-align: center;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;
}

.containers .cards:hover .content
{
  visibility: visible;
  opacity: 1;
  margin-top: 40px;
  transition-delay: 0.3s;
  height: fit-content;
}

.custom-card {
  background: none;
}

@media (max-width: 330px){
    .containers .cards .imgContainer{
        left: -2px;
    }
}

@media (max-width: 285px) {
  iframe {
    width: 95%;
    color: #090909;
  }
}

#home {
  /* background-image: url(./images/image.jpeg) !important; */
  background-blend-mode: multiply;
  background-color: white;
  background-attachment: fixed;
}

input::placeholder,
textarea::placeholder {
  opacity: 1; /* Firefox */
  color: hsl(213, 73%, 50%);
}

.member {
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3); */
  transition: 0.3s;
}

.member:hover {
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.1);
}

.partners {
  Width: 19%;
  Aspect-ratio: 3/2;
  Object-fit: contain;
}


.portfolio {
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(10, 10, 10, 0.6)), url(./images/SoftwareBackground.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.serve {
  background:linear-gradient(0deg, rgba(10, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(./images/erpReport.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.software-service {
  background-image: url(./images/SoftwareBackground.jpeg);
  background-blend-mode: multiply;
  /* background-color: orange; */
}

.footer-logo{
  Width: 70%;
Aspect-ratio: 3/2;
Object-fit: contain;
/* Mix-blend-mode: color-burn; */
}
.card-content {
  Width: 100%;
  Aspect-ratio: 3/2;
  Object-fit: contain;
}

main {
  font-family: "Roboto", sans-serif;
  margin: 5vw;
  padding: 0vw;
}

.new-card {
  width: 17.5em;
  height: 20em;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: #f0f0f0;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
}
.new-card img {
  position: absolute;
  object-fit: cover;
  Width: 100%;
  height: 100%;
  Aspect-ratio: 3/2;
  opacity: 0.9;
  transition: opacity 0.2s ease-out;
}
.new-card h2 {
  position: absolute;
  inset: auto auto 120px 30px;
  margin: 0;
  transition: inset 0.3s 0.3s ease-out;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}
.new-card p, .new-card a {
  position: absolute;
  opacity: 0;
  max-width: 90%;
  transition: opacity 0.3s ease-out;
}
.new-card p {
  inset: auto auto 10px 30px;
}
.new-card a {
  inset: auto auto 40px 30px;
  color: inherit;
  text-decoration: none;
}
.new-card:hover h2 {
  inset: auto auto 220px 30px;
  transition: inset 0.3s ease-out;
}
.new-card:hover p, .new-card:hover a {
  opacity: 1;
  transition: opacity 0.5s 0.1s ease-in;
}
.new-card:hover img {
  transition: opacity 0.3s ease-in;
  opacity: 1;
}

.material-symbols-outlined {
  vertical-align: middle;
}

.software-img{
  background-image: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(./images/software.jpg);
  background-size: cover;
}
.network-img{
  background-image: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(./images/networks.jpg);
  background-size: cover;
}
.security-img{
  background-image: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(./images/security-img.jpg);
  background-size: cover;
}
.audit-img{
  background-image: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(./images/contract.jpg);
  background-size: cover;
}
.consultation-img{
  background-image: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(./images/consultation.jpg);
  background-size: cover;
}
.support-img{
  background-image: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(./images/support.jpg);
  background-size: cover;
}

.team {
  background: linear-gradient(0deg, rgba(0,0,0,0.8), rgba(10,10,10,0.8)), url(./images/contract.jpg);
  background-size: cover;
}

/* start contact */
#contact
    {
        background-image: linear-gradient(0deg, rgba(0,0,0,0.4), rgba(0,0,0,0.8)), url('./images/consultation.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff;
    }
#contact h2
    {
        padding-bottom: 10px;
    }
#contact address
    {
        padding-top: 20px;
    }
#contact address .fa
    {
        background: #28a7e9;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-top: 6px;
        margin-right: 10px;
    }
#contact .contact-form
    {
        padding-top: 40px;
    }
#contact .form-control
    {
        border: none;
        border-radius: 0px;
        box-shadow: none;
        margin-bottom: 20px;
    }
#contact input
    {
        height: 50px;
    }
#contact input[type="submit"]
    {
        background: #28a7e9;
        color: #fff;
        font-weight: bold;
        transition: all 0.3s ease;
    }
#contact input[type="submit"]:hover {
    background: #107DB6;
}
/* end contact */


/* start for last form */
.contact-content {
  /* margin-top: -240px; */
  position: relative;
  /* top: 10em; */
  z-index: 0;
}

.contact-content #map {
  border-radius: 10px;
  margin-bottom: 60px;
}
.contact-content iframe {
  border:0; 
  border-radius: 10px; 
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}
.contact-content .item {
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  padding: 35px 30px;
  background-color: #fff;
}
.contact-content .item img {
  max-width: 52px;
}
.contact-content .phone {
  margin-right: 15px;
}

.contact-content .email {
  margin-left: 15px;
}

.contact-content .item img {
  float: left;
  margin-right: 25px;
  vertical-align: middle;
}

.contact-content .item h6 {
  font-size: 20px;
  font-weight: 600;
  vertical-align: middle;
}

.contact-content .item h6 span {
  font-size: 15px;
  color: #aaaaaa;
  font-weight: 400;
}

.contact-content #contact-form {
  margin-left: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  padding: 35px 30px;
  background-color: #fff;
}

.contact-content #contact-form label {
  font-size: 15px;
  color: #3a3a3a;
  margin-bottom: 15px;
}

.contact-content #contact-form input {
  width: 100%;
  height: 44px;
  border-radius: 22px;
  background-color: #f6f6f6;
  border: none;
  margin-bottom: 30px;
  font-size: 14px;
  padding: 0px 15px;
}

.contact-content #contact-form textarea {
  width: 100%;
  height: 150px;
  max-height: 180px;
  border-radius: 22px;
  background-color: #f6f6f6;
  border: none;
  margin-bottom: 40px;
  font-size: 14px;
  padding: 15px 15px;
}

.contact-content #contact-form button {
  background-color: #1e1e1e;
  height: 44px;
  border-radius: 22px;
  padding: 0px 20px;
  color: #fff;
  border: none;
  font-size: 15px;
  font-weight: 500;
  transition: all .5s;
}

.contact-content #contact-form button:hover {
  background-color: #f35525;
}

@media (max-width: 992px) {
  .contact-content .phone {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .contact-content .email {
    margin-left: 0px;
    margin-bottom: 45px;
  }
  .contact-content #contact-form {
    margin-left: 0px;
  }
}
/* end for last form */
